import React from 'react';
import ThreeSixtyThumbnails from '../threeSixtyThumbnails/threeSixtyThumbnails';
import './threeSixtyView.scss';
import { IResponseData } from '../../types/VariantSelection';
import * as helper from '../common/scs-html-helper';
import CloseIconLarge from '../../Assets/svg/icon_close_large';
import { IThreeSixtyViewProps, IThreeSixtyOverlayState } from './threeSixtyView.d';
import { ThreeSixtyViewDataController } from '../common/threeSixtyViewDataController';
import ThreeSixtyAnimation from '../threeSixtyAnimation/threeSixtyAnimation';
import { IThreeSixtyAnimationProps } from '../threeSixtyAnimation/threeSixtyAnimation.d';

export default class ThreeSixtyView extends React.Component<IThreeSixtyViewProps, IThreeSixtyOverlayState> {

    private threeSixtyViewController: ThreeSixtyViewDataController;
    private tsAnimationRef = React.createRef<ThreeSixtyAnimation>();
    private tsThumbnailsRef = React.createRef<ThreeSixtyThumbnails>();

    constructor(props) {
        super(props);

        // Startup state:
        this.state = {
            currentThreeSixty: this.props.threeSixty,
            currentThreeSixtyThumbnails: this.props.threeSixtyThumbnails,
            threeDIsAvailable: (this.props.threeSixty && this.props.threeSixty.imageUrlArray
                && this.props.threeSixty.imageUrlArray.length > 0),
            colorCode: this.props.colorCode,
            hasThreeSixtyThumbnails: this.props.threeSixtyThumbnails
                ? this.props.threeSixtyThumbnails.length > 0 : false
        };

        this.threeSixtyViewController = ThreeSixtyViewDataController.instance;

        this.handleThreeSixtyThumbnailSelection = this.handleThreeSixtyThumbnailSelection.bind(this);
        this.handleCloseGallery3d = this.handleCloseGallery3d.bind(this);
        this.hideShowCase = this.hideShowCase.bind(this);
        this.restoreShowCase = this.restoreShowCase.bind(this);
        this.handleESPPDataUpdate = this.handleESPPDataUpdate.bind(this);
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && window.shell
            && !this.props.doNotUpdateAfterVariantChange) {
            window.shell.subscribeTo(
                'ESPP.DetailPage.PageUpdate',
                (response: IResponseData) => {
                    if (this.state.colorCode !== response.variantSelection.selectedColor.code) {
                        this.setState({colorCode: response.variantSelection.selectedColor.code}, () => {
                            this.handleESPPDataUpdate(response.variantSelection.selectedColor.code, true);
                        });
                    }
                },
                this.props.containerId);

            window.shell.subscribeTo(
                'ESPP.OrderModal.Update',
                ({ showCaseContainerId, response, restartAnimation }:
                    { showCaseContainerId: string, response: IResponseData, restartAnimation: boolean }
            ) => {
                    const isTargetContainer = showCaseContainerId === this.props.containerId;
                    if (isTargetContainer && this.state.colorCode !== response.variantSelection.selectedColor.code) {
                        this.setState({ colorCode: response.variantSelection.selectedColor.code }, () => {
                            this.handleESPPDataUpdate(
                                response.variantSelection.selectedColor.code, 
                                (restartAnimation !== undefined && restartAnimation !== null)
                                ? restartAnimation : true);
                        });
                    }
                },
                this.props.containerId);
        }

        if(this.state.threeDIsAvailable)
            setTimeout(() => {
                if (this.tsAnimationRef.current)
                    this.tsAnimationRef.current.initAnimation();
            }, 500);
        else
            this.hideShowCase();
    }

    // dedicated only for tests:
    public get getThreeSixtyAnimationRef() {
        return this.tsAnimationRef;
    }

    private handleESPPDataUpdate(colorCode: number, restartAnimation: boolean): void {
        this.threeSixtyViewController.getThreeSixtyViewData(colorCode, this.props.masterArticleNo, 
            this.props.generalData.originalRootPath)
            .then((threeSixtyView) => {
                this.updateCurrentData(threeSixtyView, colorCode, restartAnimation);
            });
    }

    public updateCurrentData(threeSixtyView: IThreeSixtyViewProps, colorCode: number, restartAnimation: boolean): void {
        if(threeSixtyView && threeSixtyView.threeSixty) {
            this.setState({
                colorCode: colorCode,
                currentThreeSixty: threeSixtyView.threeSixty,
                currentThreeSixtyThumbnails: threeSixtyView.threeSixtyThumbnails,
                hasThreeSixtyThumbnails: threeSixtyView.threeSixtyThumbnails 
                    && threeSixtyView.threeSixtyThumbnails.length > 0
            }, ()=> {
                this.restoreShowCase(restartAnimation);
            });
        }
        else {
            this.hideShowCase();
        }
    }

    public handleThreeSixtyThumbnailSelection(threeSixty: IThreeSixtyAnimationProps): void {
        // update current three sixty view:
        this.setState({
            currentThreeSixty: threeSixty
        });
        this.tsAnimationRef.current.restartAnimation(true);
    }

    private handleCloseGallery3d(): void {
        window.shell.publishTo('SCS.Close.Gallery3DView', null);
        this.tsAnimationRef.current.resetDefaultImageSize();
    }
    
    public hideShowCase(): void {
        // hide 3d button:
        this.setState({threeDIsAvailable: false});
        window.shell.publishTo('SCS.Hide.Fragment',
            {hidden: true, containerId: this.props.containerId});
    }

    private restoreShowCase(restartAnimation: boolean): void {
        // restore 3d button:
        this.setState({threeDIsAvailable: true}, ()=> {
            this.tsAnimationRef.current.initAnimation();
            this.tsAnimationRef.current.restartAnimation(restartAnimation);
            
            if(this.tsThumbnailsRef.current)
                this.tsThumbnailsRef.current.restartAnimation();
        });
        window.shell.publishTo('SCS.Hide.Fragment',
            {hidden: false, containerId: this.props.containerId});
    }

    public render() {
        return (
            (this.props.showOverlayAnimation && !helper.isMobileShop()) ?
                <div className={'three_sixty_view'} data-testid={'three_sixty_view'}>
                    <div className={'gallery_buttons'}>
                        <div className={'close_g3d_overlay_btn'} onClick={this.handleCloseGallery3d}
                            data-testid={'close_g3d_overlay_btn'}>
                            <CloseIconLarge />
                        </div>
                    </div>
                    <div className={'three_sixty_container'} data-testid={'three_sixty_container'}>
                        <ThreeSixtyAnimation
                            ref={this.tsAnimationRef}
                            imageUrlArray={this.state.currentThreeSixty.imageUrlArray}
                            animationSpeed={this.state.currentThreeSixty.animationSpeed}
                            animationSequence={this.state.currentThreeSixty.animationSequence}
                            showOverlayAnimation={this.props.showOverlayAnimation}
                            containerId={this.props.containerId} 
                            hasThreeSixtyThumbnails={this.state.hasThreeSixtyThumbnails}
                            loadContentAfterPageLoad={false}
                            loadContentAfterPageLoadMobile={false}
                            generalData={this.props.generalData}
                            preventAutoPlay={true}
                        />
                    </div>
                    <ThreeSixtyThumbnails 
                        ref={this.tsThumbnailsRef}
                        threeSixtyThumbnails={this.state.currentThreeSixtyThumbnails}
                        onThreeSixtyThumbnailSelected={this.handleThreeSixtyThumbnailSelection} 
                    />
                </div>
                :
                <>
                    {
                        (this.props.showOverlayAnimation && helper.isMobileShop()) &&
                        <div className={'mobile_gallery_buttons'}>
                            <div className={'close_g3d_overlay_btn'} onClick={this.handleCloseGallery3d}>
                                <CloseIconLarge />
                            </div>
                        </div>
                    }
                    {
                        this.state.threeDIsAvailable &&
                        <ThreeSixtyAnimation
                            ref={this.tsAnimationRef}
                            imageUrlArray={this.state.currentThreeSixty.imageUrlArray}
                            animationSpeed={this.state.currentThreeSixty.animationSpeed}
                            animationSequence={this.state.currentThreeSixty.animationSequence}
                            showOverlayAnimation={this.props.showOverlayAnimation}
                            containerId={this.props.containerId}
                            hasThreeSixtyThumbnails={false}
                            loadContentAfterPageLoad={this.props.loadContentAfterPageLoad}
                            loadContentAfterPageLoadMobile={this.props.loadContentAfterPageLoadMobile}
                            generalData={this.props.generalData}
                            preventAutoPlay={this.props.preventAutoPlay}
                        />
                    }
                    <img src={this.props.fallBackImageUrl}
                        className={!this.state.threeDIsAvailable ? 'fall_back_img fbi_show': 'fall_back_img'} alt=''
                        data-index={1}
                        key={1}/>
                </>
        );
    }
}
